<template>
  <transition-group name="bounce" mode="out-in" v-if="correctAnswerScreen.fields.title || wrongAnswerScreen.fields.title">
    <div class="bounce feedbackArea" v-if="feedback === 'correct'" key="feedback-correct">
      <Screen
        :key="correctAnswerScreen.fields.title"
        :data="correctAnswerScreen"
        v-on:next="emitNext()"
        v-on:retry="emitRetry()"
      ></Screen>
    </div>
    <div class="bounce feedbackArea" v-if="feedback === 'wrong'" key="feedback-wrong">
      <Screen
        :key="wrongAnswerScreen.fields.title"
        :data="wrongAnswerScreen"
        v-on:next="emitNext()"
        v-on:retry="emitRetry()"
      ></Screen>
    </div>
  </transition-group>    
</template>

<script>
import Screen from 'Components/questions/Screen'

export default {
  name: 'QuestionHeader',

  props: [ 'data', 'feedback' ],

  components: {
    Screen,
    Icons: () => import('Components/base/Icons')
  },

  data () {
    return {
      correctAnswerScreen: {
        fields: {},
        sys: {}
      },
      wrongAnswerScreen: {
        fields: {},
        sys: {}
      }
    }
  },

  methods: {
    emitRetry () {
      this.$emit('retry')
    },
    emitNext () {
      this.$emit('next')
    }
  },

  // Load the feedback screens
  async mounted () {
    if (this.data.fields.correctAnswerScreen) {
      this.correctAnswerScreen.fields = await this.$store.dispatch('LOAD_ENTRY', { entryId: this.data.fields.correctAnswerScreen.sys.id })
      this.correctAnswerScreen.sys = this.data.fields.correctAnswerScreen.sys
    }
    if (this.data.fields.wrongAnswerScreen) {
      this.wrongAnswerScreen.fields = await this.$store.dispatch('LOAD_ENTRY', { entryId: this.data.fields.wrongAnswerScreen.sys.id })
      this.wrongAnswerScreen.sys = this.data.fields.wrongAnswerScreen.sys
    }
  },

  watch: {
    feedback: function (newVal) {
      if (newVal === 'correct') {
        if (!this.data.fields.correctAnswerScreen) {
          this.emitNext()
        }
      } else {
        if (!this.data.fields.wrongAnswerScreen) {
          this.emitNext()
        }
      }

    }
  }

}
</script>

<style lang="scss">
.feedbackArea {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}
.feedbackArea {
  overflow: scroll;  
}
.full-area.scrollable {
  padding: 10px 20px 2em;
}
</style>
